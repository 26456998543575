<template>
    <div id="app">
        <div v-if="isLoggedIn">
            <router-view to="/platform"></router-view>
        </div>
        <div v-else>
            <transition name="slide-fade" mode="out-in">
                <router-view to="/login"></router-view>
            </transition>
        </div>
    </div>
</template>
<script>
export default {
    name: 'App',
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        }
    },
    methods: {
        async logout()
        {

        }
    },
}
</script>

<style>
.slide-fade-enter-active {
    transition: all .1s ease;
}

.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}
</style>
